import React, {useEffect, useState} from "react";
import { useMap, MapboxGeoJSONFeature } from "react-map-gl";

import {times, mean, sum} from "lodash";

import { Box, Paper, Stack, Typography, Card, CardContent, InputLabel, Switch, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Info, House, DirectionsWalk, Bedtime, WbTwilight, Train, Coffee, Restaurant, School, Commute, Hotel } from "@mui/icons-material";
import {useTheme, alpha} from "@mui/material/styles";

import {toPercentage, toIndex, toPercentileName} from "@/utils/common";
import {InsightResult, MovementRateResult, MovementResult} from "@/types";
import { movementOptions } from "@/constants"

type MovementOverlayProps = {
  data: MovementResult[];
  insight: InsightResult[];
  selected?: MapboxGeoJSONFeature;
  clearSelected: () => void;
  firstTime: boolean;
};

const MovementOverlay: React.FC<MovementOverlayProps> = ({ data, insight, selected, clearSelected, firstTime }) => {


  const { current: map } = useMap();
  const theme = useTheme();

  const [areaScores, setAreaScores] = useState<MovementResult[]>([]);
  const [districtScores, setDistrictScores] = useState<MovementResult[]>([]);
  const [sectorScores, setSectorScores] = useState<MovementResult[]>([]);

  const [timePeriod, setTimePeriod] = useState<number>(0);
  const [weekend, setWeekend] = useState<boolean>(false);

  const oohRate = sum(insight.filter(x => x.tag_id > 70002 && x.tag_id < 70008).map(x => x.rate));
  const oohScore = oohRate / 0.794
  const homeRate = 1 - oohRate;
  const homeScore = 1 / oohScore;

  useEffect(() => {
    setAreaScores(applyFilter(data, 'Postal Area', timePeriod, weekend));
    setDistrictScores(applyFilter(data, 'Postal District', timePeriod, weekend));
    setSectorScores(applyFilter(data, 'Postal Sector', timePeriod, weekend));
  }, [data, timePeriod, weekend]);

  const applyFilter = (data: MovementResult[], level: string, timePeriod: number, isWeekend: boolean) => {
    return data.filter(
      (x: MovementResult) => x.tag_group == level
    ).filter((
      x: MovementResult) => x.feature_id == (isWeekend  && timePeriod > 0 ? timePeriod + 9 : timePeriod) )
  }

  const toLabel = (value: number) => {
    if (value > 1.01) {
      return 'above'
    } else if (value < 0.99) {
      return 'below'
    } else {
      return ''
    }
  }

  const movementIcons: {[key: number]: any} = {
    0: House,  // Home
    1: DirectionsWalk,  // Out and about
    2: WbTwilight,  // Early Morning
    3: Train,  // Morning Commute
    4: Coffee,  // Late Morning
    5: Restaurant,  // Lunchtime
    6: School,  // Afternoon
    7: Commute,  // Evening Commute
    8: Hotel,  // Late Night
    9: Bedtime,  // Night Owls
  }

  const insightMap: {[key: number]: string} = {
    2: 'Early Morning',
    3: 'Morning Commute',
    4: 'Late Morning',
    5: 'Lunchtime',
    6: 'Afternoon',
    7: 'Evening Commute',
    8: 'Late Night',
    9: 'Night Owls',
  }

  useEffect(() => {
    if (!firstTime) {
      console.log('UPDATING')
      map && map.isStyleLoaded() && resetMap();
      map && areaScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 113,
            source: "area-source",
            sourceLayer: "postal_area_test",
          },
          { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group },
        );
      });
      map && districtScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 237,
            source: "district-source",
            sourceLayer: "postal_district_test",
          },
          { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group }
        );
      });
      map && sectorScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 3076,
            source: "sector-source",
            sourceLayer: "postal_sector_test",
          },
          { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group }
        );
      });
    }

  }, [map, areaScores, districtScores, sectorScores]);

  const resetMap = () => {
    map && [...times(121)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'area-source', sourceLayer: 'postal_area_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
    map && [...times(2816)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'district-source', sourceLayer: 'postal_district_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
    map && [...times(9469)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'sector-source', sourceLayer: 'postal_sector_test'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
  };

  const handleTimePeriodChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    newValue ? setTimePeriod(Number(newValue)) : setTimePeriod(0);
    selected && clearSelected();

  }

  const handleDayTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeekend(event.target.checked);
    selected && clearSelected();
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <Tooltip
          title={`
            This map highlights the best postal sectors, districts and areas to reach your audience.
            The colour of the map represents the penetration of your audience into each geo, so the higher
            the value (and the darker the colour) the larger proportion of the geo is made up by your audience. 
            For example, a penetration of 50% shows that your audience reaches 50% of the selected geo. 
            The larger the geo, the smaller this penetration is likely to be.
          `}
        >
          <Info fontSize="small"/>
        </Tooltip>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: '540px',
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  Select a Time Period
                </Typography>
                <Tooltip
                  title={`
                  Change the time period to show where your audience is likely to be at different times of the day,
                  averaged during the week or over the weekend.
                  `}
                >
                  <Info fontSize="small"/>
                </Tooltip>
              </Box>
              <Stack direction="row" spacing={2}>
                <ToggleButtonGroup
                  value={timePeriod}
                  exclusive
                  size="small"
                  color="primary"
                  onChange={handleTimePeriodChange}
                  aria-label="Time Period"
                >
                  {movementOptions.slice(0, 2).map(({value, label}) => {
                    const rate = (value === 0 ? homeRate : oohRate);
                    const score = (value === 0 ? homeScore : oohScore);
                    const Icon = movementIcons[value];

                    return (
                      <Box key={value}>
                        <Tooltip
                          title={<span>{[
                            label,
                            <br/>,
                            <br/>,
                            value === 0 ?
                              `${toPercentage(rate)} of your audience is likely to be at home during the day, which is ${toLabel(score)} average, with an index of ${toIndex(score)}`
                              : `${toPercentage(rate)} of your audience is likely to be out and about during the day, which is ${toLabel(score)} average, with an index of ${toIndex(score)}`,
                            <br/>,
                            <br/>,
                            '(These numbers are for weekdays only, weekend data is coming soon)'
                          ]}
                          </span>}
                        >
                          <ToggleButton value={value} sx={{width: '50px'}}>
                            <Stack spacing={1} alignItems="center">
                              <Icon/>
                              <Stack>
                                <Typography variant="caption">{toPercentage(rate)}</Typography>
                                <Typography variant="caption">{toIndex(score)}</Typography>
                              </Stack>
                            </Stack>
                          </ToggleButton>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  value={timePeriod}
                  exclusive
                  size="small"
                  color="primary"
                  onChange={handleTimePeriodChange}
                  aria-label="Time Period"
                >
                  {movementOptions.slice(2).map(({value, label}) => {
                    const Icon = movementIcons[value];
                    const rate = insight.filter(x => x.tag_name === insightMap[value])[0].rate;
                    const score = insight.filter(x => x.tag_name === insightMap[value])[0].score;

                    return (
                      <Box key={value}>
                        <Tooltip
                          title={<span>{[
                            label,
                            <br/>,
                            <br/>,
                            `${toPercentage(rate)} of your audience is most active during this time which is ${toLabel(score)} average, with an index of ${toIndex(score)}`,
                            <br/>,
                            <br/>,
                            '(These numbers are for weekdays only, weekend data is coming soon)'
                          ]}
                          </span>}
                        >
                          <ToggleButton value={value} sx={{ width: '50px'}}>
                            <Stack spacing={1} alignItems="center">
                              <Icon />
                              <Stack>
                                <Typography variant="caption">{toPercentage(rate)}</Typography>
                                <Typography variant="caption">{toIndex(score)}</Typography>
                              </Stack>
                            </Stack>
                          </ToggleButton>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </ToggleButtonGroup>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="textSecondary">
                Select a Day Period
              </Typography>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography variant="body2">Weekday</Typography>
                <Tooltip title="Toggle between showing movement patterns during the week or over the weekend">
                  <Switch
                    size="small"
                    color="primary"
                    checked={weekend}
                    onChange={handleDayTypeChange}
                    disabled={timePeriod === 0}
                  />
                </Tooltip>
                <Typography variant="body2">Weekend</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>

      </Box>



      <Box sx={{ position: "relative" }}>
        {selected && Object.keys(selected.state).length > 0 && (
          <Card sx={{ position: "absolute", top: 12, right: 12 }}>
            <CardContent
              sx={{ p: 2, "&:last-child": { pb: 2 }, minWidth: "150px" }}
            >
              <Stack direction="row" spacing={2}>
                <Typography>{`${selected?.state.level}: ${selected?.state.name}`}</Typography>
                <Tooltip
                  title={<span>{[
                    `
                    For the selected time and day period, we use movement data to calculate where your audience is likely 
                    to be. This is represented as a percentile, with 0 being the lowest and 100 being the highest, and 
                    can be interpreted as the higher the score, the more likely you are to reach someone in your audience. 
                    `,
                    <br/>,
                    <br/>,
                    `
                    If looking at home locations, the penetration represents the percentage of the geo that your audience makes up. 
                    For example, a penetration of 50% shows that your audience reaches 50% of the selected geo. 
                    The larger the geo, the smaller this penetration is likely to be.
                    `
                  ]}
                          </span>}
                >
                  <Info fontSize="small"/>
                </Tooltip>
              </Stack>

              <Typography variant="body2">
                {`Score: ${toPercentileName(selected?.state.score)}`}
              </Typography>
              {timePeriod === 0 &&
                <Typography variant="body2">
                  {`Penetration: ${toPercentage(selected?.state.percentage)}`}
                </Typography>
              }
            </CardContent>
          </Card>
        )}
      </Box>


    </>
  );
};

export default MovementOverlay;
