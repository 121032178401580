import React, { useState, useEffect } from "react";
import {Box, Card, CardContent, CardHeader, FormGroup, FormControlLabel, Typography, Tooltip, Switch, Grid} from "@mui/material";
import {InsightResult} from "@/types";
import {darken} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { sortBy } from "lodash";
import { toPercentage, toIndex } from "@/utils/common"



const mediaQuintiles: {[name: string]: number} = {
  'Quintile 1 (Highest)': 3,
  'Quintile 2': 2,
  'Quintile 3': 1,
  'Quintile 4': -1,
  'Quintile 5 (Lowest)': -2,
  'None': -3,
}

const scoreTypeMap: {[name: string]: string} = {
  'score': 'Score',
  'rate': 'Penetration'
}

type MediaScore = {
  name: string,
  rate: number,
  score: number,
  index: number,
}

interface MediaCardProps {
  name: string;
  data: InsightResult[];
  groups: string[];
}

export const MediaCard: React.FC<MediaCardProps> = ({name, data, groups}) => {

  const theme = useTheme();
  const [scores, setScores] = useState<MediaScore[]>([]);
  const [scoreChecked, setScoreChecked] = useState<boolean>(true);
  const [scoreType, setScoreType] = useState<string>('score');

  useEffect(() => {

    let tmpScores = sortBy(groups.map((group, idx) => (
      {
        name: group,
        score: (data.filter((x) => x.tag_category === group).filter(x => String(x.tag_name) !== '0').slice(0, 3).map(x => mediaQuintiles[x.tag_name] * x.score).reduce((a, b) => a + b, 0) / 3) + 3,
        rate: data.filter((x) => x.tag_category === group).filter((x) => ['Quintile 1 (Highest)', 'Quintile 2'].includes(x.tag_name)).map(x => x.rate).reduce((a, b) => a + b, 0),
        index: data.filter((x) => x.tag_category === group).filter((x) => ['Quintile 1 (Highest)', 'Quintile 2'].includes(x.tag_name)).map(x => x.score).reduce((a, b) => a + b, 0) / 2
      }
    )), scoreType).reverse()

    setScores(tmpScores)

  }, [scoreType, data, groups])

  const handleScoreTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScoreChecked(event.target.checked);
    setScoreType(event.target.checked ? 'score' : 'rate');
  };

  function toScore(score: MediaScore, mode: string) {
    if (mode === 'score') {
      return score.score / 6;
    } else {
      let minScore = Math.min(...scores.map(x => x[scoreType as keyof MediaScore] as number));
      let maxScore = Math.max(...scores.map(x => x[scoreType as keyof MediaScore] as number));
      if (minScore >= 1) {
        return 1
      } else {
        return (((0.9 - 0.05) * (score.rate - minScore)) / (maxScore - minScore)) + 0.05;
      }
    }
  }

  function toLabel(score: MediaScore, mode: string) {
    if (mode === 'score') {
      return toIndex(score.score);
    } else {
      return toPercentage(score.rate)
    }
  }

  function toArray(values: number[], step: number) {

    let start = (Math.round((Math.min(...values) * 100) / 10) * 10) / 100;
    let stop = (Math.ceil((Math.max(...values) * 100) / 5) * 5) / 100;
    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  }

  return (
    <Card elevation={8} sx={{ height: '600px'}}>
      <CardHeader
        disableTypography
        title={
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              {name}
            </Typography>
            <FormGroup>
              <Tooltip
                placement="top"
                title={`
                  Change the type of data shown. 
                  The penetration shows the percentage of the audience that is likely to use this media type
                  and the score represents how likely they are to notice or respond to it.
                `}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={scoreChecked}
                      onChange={handleScoreTypeChange}
                    />}
                  label={scoreTypeMap[scoreType]}
                  labelPlacement="start"
                />
              </Tooltip>
            </FormGroup>
          </Box>
        }
        subheader={
          <Typography color="textSecondary">
            Which media type is more likely to resonate with this audience
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={5}>
          </Grid>
          { scoreType === 'score' &&
            <Grid item xs={7} sx={{ borderBottom: '1px solid white'}} display="flex" justifyContent="space-between">
              <Typography variant="caption">None</Typography>
              <Typography variant="caption"> </Typography>
              <Typography variant="caption">Low</Typography>
              <Typography variant="caption"> </Typography>
              <Typography variant="caption">Medium</Typography>
              <Typography variant="caption"> </Typography>
              <Typography variant="caption">High</Typography>
              <Typography variant="caption"> </Typography>
            </Grid>
          }
          { scoreType === 'rate' &&
            <Grid item xs={7} sx={{ borderBottom: '1px solid white'}} display="flex" justifyContent="space-between">
              {toArray(scores.map(x => x[scoreType as keyof MediaScore] as number), 0.05).map((val, idx) => (
                  <Typography key={idx} variant="caption">{toPercentage(val, 0)}</Typography>
                ))
              }
            </Grid>
          }
          {scores.map((score, idx) => (
            <>
              <Grid item xs={5}>
                <Typography>{score.name}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Tooltip placement="right" title={`${scoreTypeMap[scoreType]}: ${toLabel(score, scoreType)}`}>
                  <Box
                    sx={{
                      width: `${(toScore(score, scoreType)) * 100}%`,
                      height: 24,
                      backgroundColor: darken(theme.palette.primary.main, 0.9 - Math.min(toScore(score, scoreType), 1)),
                      borderRadius: "0px 4px 4px 0px"
                  }}
                  />
                </Tooltip>
              </Grid>
            </>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )

}

export default MediaCard;
