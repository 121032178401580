import { AxiosRequestConfig } from "axios";

import { ApiError } from "@/interfaces/api";
import { AudienceSize, FileDownloadResult } from "@/types";

import { fetchData } from "@/api/utils";

type ExportAudienceApiResponse = {
  data: FileDownloadResult;
  error: ApiError | null;
};

const exportAudience = async (
  accessToken: string,
  selectionIds: number[],
  exportOption: string,
  timePeriod: number,
  audienceSize: AudienceSize
): Promise<ExportAudienceApiResponse> => {
  let url = `${
    process.env.REACT_APP_API_URL
  }/t7s/campaign/connect?csv=true&link=true&request_id=${
    audienceSize.request_id
  }&export_type=${exportOption.toLowerCase()}&time_period=${timePeriod}`;

  url =
    selectionIds.length === 0 ? url : url + `&selection=${selectionIds.join()}`;

  const config: AxiosRequestConfig = {
    method: "GET",
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export default exportAudience;
