import {
  darkMapStyle,
  lightMapStyle,
} from "@/components/molecules/Map/MovementMap/config";
import React, { useState, useCallback, useMemo } from "react";

import Map, {
  Source,
  Layer,
  AttributionControl,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  areaBaseLayer,
  areaHoverLayer,
  areaSelectLayer,
  districtBaseLayer,
  districtHoverLayer,
  districtSelectLayer,
  sectorBaseLayer,
  sectorHoverLayer,
  sectorSelectLayer
} from "./MovementLayers";

import {getMsoaDataBreaks} from "@/utils/common";

import { default as MovementOverlay } from "./MovementOverlay";

import {InsightResult, MovementResult} from "@/types";

type MovementMapProps = {
  data: MovementResult[];
  insight: InsightResult[];
  mapStyle?: string;
};

const MovementMap: React.FC<MovementMapProps> = ({
  data,
  insight,
  mapStyle,
}) => {
  const MAP_STYLE = mapStyle === "light" ? lightMapStyle : darkMapStyle;

  const [selected, setSelected] = useState<MapboxGeoJSONFeature | undefined>(undefined);
  const [hovered, setHovered] = useState<number>(0);
  const [cursor, setCursor] = useState<string>("auto");
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [dataBreaks, setDataBreaks]= useState<number[]>([]);

  const areaScores = data.filter((x) => x.tag_group === "Postal Area" && x.feature_id === 0);
  const districtScores = data.filter((x) => x.tag_group === "Postal District" && x.feature_id === 0);
  const sectorScores = data.filter((x) => x.tag_group === "Postal Sector" && x.feature_id === 0);

  const onLoad = useCallback((event) => {
      const map = event.target;
      map &&
        areaScores.forEach((value) => {
          map.setFeatureState(
            {
              id: value.tag_id - 113,
              source: "area-source",
              sourceLayer: "postal_area_test",
            },
            { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group },
          );
        });

      map &&
      districtScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 237,
            source: "district-source",
            sourceLayer: "postal_district_test",
          },
          { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group }
        );
      });
      map &&
      sectorScores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 3076,
            source: "sector-source",
            sourceLayer: "postal_sector_test",
          },
          { score: value.score_norm, percentage: value.score, name: value.tag_name, level: value.tag_group }
        );
      });
      setDataBreaks(getMsoaDataBreaks(areaScores));
    },
    [areaScores, districtScores, sectorScores]
  );

  const onStyleData = useCallback((event) => {
    setFirstTime(false);
  }, []);

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];
    if (feature && Object.keys(feature.state).length > 0 && feature.state.score) {
      setSelected(feature);
    } else {
      clearSelected();
    }
  }, []);

  const onHover = useCallback((event) => {
    const feature = event.features && event.features[0];
    if (feature && Object.keys(feature.state).length > 0 && feature.state.score) {
      setHovered(feature.id);
    } else {
      setHovered(0);
    }
  }, []);

  const clearSelected = () => {
    setSelected(undefined);
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  const hoverFilter = useMemo(() => ["==", ["id"], hovered], [hovered]);
  const selectFilter = useMemo(
    () => ["==", ["id"], selected ? selected.id : 0],
    [selected]
  );

  return (
    <Map
      key={MAP_STYLE}
      initialViewState={{
        longitude: -4,
        latitude: 55,
        zoom: 4.8,
      }}
      projection={{ name: "mercator" }}
      minZoom={4}
      maxBounds={[[-30, 36], [30, 66]]}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_MAP_TOKEN}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onLoad={onLoad}
      onStyleData={onStyleData}
      onMouseMove={onHover}
      interactiveLayerIds={["area-base", "district-base", "sector-base"]}
      attributionControl={false}
    >
      <AttributionControl customAttribution="" />
      <Source
        id="area-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_area_test"
      >
        <Layer beforeId="waterway-label" {...areaBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...areaHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...areaSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <Source
        id="district-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_district_test"
      >
        <Layer beforeId="waterway-label" {...districtBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...districtHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...districtSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <Source
        id="sector-source"
        type="vector"
        url="mapbox://mpilarczykstarcount.postal_sector_test"
      >
        <Layer beforeId="waterway-label" {...sectorBaseLayer} />
        <Layer
          beforeId="waterway-label"
          {...sectorHoverLayer}
          filter={hoverFilter}
        />
        <Layer
          beforeId="waterway-label"
          {...sectorSelectLayer}
          filter={selectFilter}
        />
      </Source>
      <MovementOverlay data={data} insight={insight} selected={selected} clearSelected={clearSelected} firstTime={firstTime}/>
    </Map>
  );
};

export default MovementMap;
