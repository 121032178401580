import React, {useEffect, useState} from "react";
import { useMap, MapboxGeoJSONFeature } from "react-map-gl";

import {times} from "lodash";

import { Box, Typography, Card, CardContent, Stack, FormControl, InputLabel } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTheme, lighten } from "@mui/material/styles";

import { toPercentage } from "@/utils/common";
import {InsightLocationResult, InsightResult} from "@/types";
import { timeOfDay, modeOfTransport } from "@/constants"

type PenPortraitOverlayProps = {
  scores: InsightResult[];
  firstTime: boolean;
};

const PenPortraitOverlay: React.FC<PenPortraitOverlayProps> = ({ scores, firstTime }) => {


  const { current: map } = useMap();

  useEffect(() => {
    if (!firstTime) {
      map && map.isStyleLoaded() && resetMap();
      map && scores.forEach((value) => {
        map.setFeatureState(
          {
            id: value.tag_id - 113,
            source: "area-source",
            sourceLayer: "areas_output",
          },
          { score: value.score_sum }
        );
      });
    }

  }, [map, scores]);

  const resetMap = () => {
    map && [...times(121)].forEach(value => {
      map.setFeatureState(
        {id: value, source: 'area-source', sourceLayer: 'areas_output'},
        {score: null, percentage: null, name: null, level: null}
      );
    });
  };

  return (
    <>

    </>
  );
};

export default PenPortraitOverlay;
